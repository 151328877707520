<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <icon-class-bar
          title="Präsenzkontrolle"
          :marks="true"
          :comments="true"
          :presence="true"
          current="presence"
          @edit-mode="editMode=true"
        />
        <v-card-text>
          <div
            align="start"
            class="d-flex flex-row"
          >
            <div
              class=""
              cols="6"
              sm="5"
            >
              <v-select
                v-model="selectedClass"
                :items="a_ClassNames"
                filled
                label="Klasse"
                dense
                outlined
                color="success"
                class="mr-4"
                @change="updateCurrentClassID"
              />
            </div>
            <div class="" cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="s_ClassDate"
                    label="Datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="s_ClassDate"
                  @input="menu2 = false"
                />
              </v-menu>
            </div>
            <div>
              <v-btn icon @click="getNearLessonDate('previous')">
                <v-icon size="50">
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn icon @click="getNearLessonDate('next')">
                <v-icon size="50">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
            <v-spacer />
          </div>
          <v-simple-table v-if="b_LessonsLoaded" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{$t('general.firstname')}}</th>
                  <th>{{$t('general.lastname')}}</th>
                  <th v-for="sublesson of a_Sublessons" v-bind:key="sublesson.i_LessonID + '-' + sublesson.i_SublessonID">
                    {{sublesson.s_SublessonName}}<br />{{sublesson.s_LessonStartTime}}
                  </th>
                </tr>
              </thead>
              <tbody v-if="b_ParticipantsLoaded">
                <tr v-for="(participant, index) of a_Participants" v-bind:key="participant.i_PersonID">
                  <th>{{ index + 1}}</th>
                  <td>{{ participant.s_Firstname }}</td>
                  <td>{{ participant.s_Lastname }}</td>
                  <td v-for="sublesson of a_Sublessons" 
                    v-bind:key="sublesson.i_LessonID + '-' + sublesson.i_SublessonID"
                  >
                    <v-btn 
                      icon 
                      x-small
                      @click="setLessonPresence(
                        participant.i_PersonID, 
                        sublesson.i_LessonID, 
                        sublesson.i_SublessonID, 
                        0, 
                        getPresenceType(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID))"
                    >
                      <v-icon 
                        :disabled="!b_LessonsLoaded" 
                        size="27"
                        :color="getColorIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID)" 
                      >
                        {{ getPresenceIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID) }}
                      </v-icon>
                    </v-btn>
                    <v-input hide-details value="input" id="presencetype"></v-input>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        selectedClass: null,
        a_Classes: [],
        a_Sublessons: [],
        a_Excuses: [],
        a_Participants: [],
        a_PresenceType: [
          {
            i_PresenceTypeID: 1,
            s_Name: 'X'
          },
          {
            i_PresenceTypeID: 2,
            s_Name: 'O'
          }
        ],
        i_CurrentClassID: null,
        b_LessonsLoaded: true,
        b_ParticipantsLoaded: true,
        search: '',
        selected: [],
        s_ClassDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
      }
    },
    watch: {
      i_CurrentClassID: function() {
        this.getClassLessons();
        this.getClassPresences();
      },
      s_ClassDate: function() {
        if(this.i_CurrentClassID != null)
          this.getClassLessons();
          this.getClassPresences();
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID']),
      a_ClassNames() {
        return this.a_Classes.map(o_Class => o_Class.title)
      },
    },
    methods: {
      ...mapActions(['signOut']),
      getClasses() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Classes = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getClassPresences() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        let classID = this.a_Classes.filter(o_Class => o_Class.title === this.selectedClass)[0].i_ClassID;
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class/${classID}/presence`, {
          s_Date: this.s_ClassDate
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Participants = response;
            this.b_ParticipantsLoaded = true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getClassLessons() {
        let Ls_SublessonName, Ls_LessonSublessonID, Li_SublessonID, Li_LessonID;
        this.a_Lessons = [];
        this.a_Sublessons = [];
        this.b_LessonsLoaded = false;
        if(this.i_CurrentClassID === null) return [];
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class/${this.i_CurrentClassID}/lesson`, {
          s_Date: this.s_ClassDate
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Lessons = response;
            this.a_Lessons.forEach((lesson)=>{
              lesson.a_Sublessons.forEach((sublesson)=>{
                if(sublesson.s_Name === '') Ls_SublessonName = lesson.s_LessonName;
                else Ls_SublessonName = lesson.s_LessonName + ' (' + sublesson.s_Name + ')';
                Ls_LessonSublessonID = lesson.i_SSB_LessonID + '-' + sublesson.i_SublessonID;
                Li_SublessonID = sublesson.i_SublessonID;
                Li_LessonID = lesson.i_SSB_LessonID;
                this.a_Sublessons.push({
                  's_SublessonName': Ls_SublessonName,
                  's_LessonSublessonID': Ls_LessonSublessonID,
                  'i_SublessonID': Li_SublessonID,
                  'i_LessonID': Li_LessonID,
                  's_LessonDate': lesson.s_LessonDate,
                  's_LessonStartTime': lesson.s_LessonStartTime
                });
              });
            });
            this.b_LessonsLoaded = true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getNearLessonDate(As_Action) {
        if(this.i_CurrentClassID === null) return [];
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class/${this.i_CurrentClassID}/lesson/near-date`, {
          s_Date: this.s_ClassDate,
          s_Action: As_Action
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            if(response.length >= 10)
              this.s_ClassDate = response.substr(0, 10);
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateCurrentClassID() {
        let classID = this.a_Classes.filter(o_Class => o_Class.title === this.selectedClass)[0].i_ClassID;
        this.i_CurrentClassID = classID;
      },
      getGroups(Ai_CourseId){
        axios.get(sessionStorage.base_url + '/api/course/groups?courseid=' + Ai_CourseId).then(
          response => {
            this.a_ClassGroups = response.data;
            if(this.a_ClassGroups.length === 1){
              this.showGroups = false;
              this.b_LessonsLoaded = true;
              //this.getClassLessons(this.a_ClassGroups[0].i_ClassGroupID);
              this.getParticipanPresences(this.a_ClassGroups[0].i_ClassGroupID);
              this.fillExcuses();
            }
            if(this.a_ClassGroups.length > 1){
              this.b_LessonsLoaded = true;
              this.showGroups = true;
            }            
          }
        ).catch(
          error => {
          }
        );
      },
      onSelectGroup(event){
        //this.getClassLessons(event);
        this.getParticipanPresences(event);
        this.fillExcuses();
      },
      setParticipantsPresences(Ai_CourseId){
        axios.post(sessionStorage.base_url + '/api/course/presences', {
          i_CourseID: Ai_CourseId,
          a_Participants: this.a_Participants
        }).then(
          response => {
          }
        ).catch(
          error => {
          }
        );
      },
      getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Lo_Presence = null; 
        const La_Participants = this.a_Participants.filter(participant => participant.i_PersonID === Ai_PersonID);
        let Lo_Participant;
        let La_PresencesByLesson;
        let La_PresencesBySublesson;

        if(La_Participants.length > 0) {
          Lo_Participant = La_Participants[0];
          La_PresencesByLesson = Lo_Participant.a_Presences.filter(presence => presence.i_SSB_LessonID === Ai_LessonID);
          La_PresencesBySublesson = La_PresencesByLesson.filter(subpresence => subpresence.i_SublessonID === Ai_SublessonID);
          if(La_PresencesBySublesson.length > 0) {
            Lo_Presence = La_PresencesBySublesson[0];
          }
        }  
        return Lo_Presence;
      },  
      getPresenceIcon(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Li_PresenceIcon = 'mdi-close-circle'; // 0: Grün, 1: Rot, 2: Orange 
        let Lo_Presence;
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        if(Lo_Presence) {
          if(Lo_Presence.i_SSN_PresenceTypeID == 2) Li_PresenceIcon = 'mdi-check-circle';
        }
        return Li_PresenceIcon;
      },
      getPresenceID: function(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Li_PresenceID = 0; 
        let Lo_Presence;
        
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        if(Lo_Presence) {
          Li_PresenceID = Lo_Presence.i_SSN_PresenceID;
        }
        
        return Li_PresenceID;
      },
      setLessonPresence(Ai_PersonID, Ai_LessonID, Ai_SublessonID, Ai_PresenceFlag,  As_PresenceType){
        if(this.setPresenceOnWork) return;
        this.setPresenceOnWork = true;
        let Li_PresenceID = this.getPresenceID(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        let Ls_PresenceType = As_PresenceType === 'I' ? 'X' : 'I'
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/period/${this.getPlanningPeriodID}/class/lesson/presence`, {
          i_PersonID: Ai_PersonID,
          i_ClassID: this.i_CurrentClassID,
          i_LessonID: Ai_LessonID,
          i_SublessonID: Ai_SublessonID,
          i_PresenceFlag: Ai_PresenceFlag,
          s_PresenceType: Ls_PresenceType,
          i_PresenceID: Li_PresenceID
        }).then(
          response => {
            this.setPresenceOnWork = false;
            this.updatePresenceType(Ai_PersonID, Ai_LessonID, Ai_SublessonID, Ls_PresenceType, Li_PresenceID, response.i_PresenceID)
          }
        ).catch(
          error => {
            this.setPresenceOnWork = false;
          }
        );
      },
      getPresenceComment: function(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Ls_Comment = '';
        let Li_PresenceID = 0;
        const La_Participants = this.a_Participants.filter(participant => participant.i_PersonID === Ai_PersonID);
        let Lo_Participant;
        const searchSublesson = (sublesson) => sublesson.i_LessonID == Ai_LessonID && sublesson.i_SublessonID == Ai_SublessonID;
        let Li_IndexSublesson = this.a_Sublessons.findIndex(searchSublesson);
        let Ls_SublessonName = this.a_Sublessons[Li_IndexSublesson].s_SublessonName;
        let Lo_Presence;
        
        if(La_Participants.length > 0) {
          Lo_Participant = La_Participants[0];
          Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
          if(Lo_Presence) {
            Ls_Comment = Lo_Presence.s_Comment;
            Li_PresenceID = Lo_Presence.i_SSN_PresenceID;
          }
        } 
        this.o_CurrentComment.s_Comment = Ls_Comment;
        this.o_CurrentComment.s_ParticipantName = Lo_Participant.s_Firstname + ' ' + Lo_Participant.s_Lastname;
        this.o_CurrentComment.i_PersonID = Ai_PersonID;
        this.o_CurrentComment.s_LessonName = Ls_SublessonName;
        this.o_CurrentComment.i_LessonID = Ai_LessonID;
        this.o_CurrentComment.i_SublessonID = Ai_SublessonID;
        this.o_CurrentComment.i_SSN_PresenceID = Li_PresenceID;
      },
      setPresenceComment(Ao_CurrentComment) {
        let Li_ApiPresenceID;
        axios.post(sessionStorage.base_url + '/api/course/presencecomment', {
          o_PresenceComment: Ao_CurrentComment
        }).then(
          response => {
            Li_ApiPresenceID = response.data.i_PresenceID;
            this.updatePresenceComment(Ao_CurrentComment.i_PersonID, Ao_CurrentComment.i_LessonID, Ao_CurrentComment.i_SublessonID, 
              Ao_CurrentComment.s_Comment, Ao_CurrentComment.i_SSN_PresenceID, Li_ApiPresenceID)
          }
        ).catch(
          error => {
          }
        );               
      },
      updatePresenceComment(Ai_PersonID, Ai_LessonID, Ai_SublessonID, As_Comment, Ai_LocalPresenceID, Ai_ApiPresenceID) {
        let Li_PresenceIndex;
        const Li_ParticipantIndex = this.a_Participants.findIndex(participant => participant.i_PersonID === Ai_PersonID);
        const searchPresence = (presence) => presence.i_SSB_LessonID == Ai_LessonID && presence.i_SublessonID == Ai_SublessonID;

        if(Ai_LocalPresenceID === 0) {
          this.a_Participants[Li_ParticipantIndex].a_Presences.push({
            'i_SSB_LessonID': Ai_LessonID,
            'i_SublessonID': Ai_SublessonID,
            'i_PresenceFlag': 0,
            'i_SSN_PresenceID':  Ai_ApiPresenceID,
            'i_SSN_PresenceTypeID': 10,
            's_Comment': As_Comment
          });
        }
        Li_PresenceIndex = this.a_Participants[Li_ParticipantIndex].a_Presences.findIndex(searchPresence);
        this.a_Participants[Li_ParticipantIndex].a_Presences[Li_PresenceIndex].s_Comment = As_Comment;
      },
      getPresenceType(Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        let Li_PresenceTypeText = 'X';
        let Lo_Presence;
        let La_PresenceType;
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        if(Lo_Presence) {
          La_PresenceType = this.a_PresenceType.filter(presenceType => presenceType.i_PresenceTypeID == Lo_Presence.i_SSN_PresenceTypeID);  
          Li_PresenceTypeText = La_PresenceType[0].s_Name;   
        }
        
        return Li_PresenceTypeText;
      },
      updatePresenceType(Ai_PersonID, Ai_LessonID, Ai_SublessonID, As_PresenceType, Ai_LocalPresenceID, Ai_ApiPresenceID){
        let Li_PresenceIndex;
        const Li_ParticipantIndex = this.a_Participants.findIndex(participant => participant.i_PersonID === Ai_PersonID);
        let Li_PresenceTypeID = this.getPresenceTypeID(As_PresenceType).i_PresenceTypeID;
        const searchPresence = (presence) => presence.i_SSB_LessonID == Ai_LessonID && presence.i_SublessonID == Ai_SublessonID;    

        if(Ai_LocalPresenceID === 0) {
          this.a_Participants[Li_ParticipantIndex].a_Presences.push({
            'i_SSB_LessonID': Ai_LessonID,
            'i_SublessonID': Ai_SublessonID,
            'i_PresenceFlag': 0,
            'i_SSN_PresenceID':  Ai_ApiPresenceID,
            'i_SSN_PresenceTypeID': Li_PresenceTypeID,
            's_Comment': ''
          });
        }
        Li_PresenceIndex = this.a_Participants[Li_ParticipantIndex].a_Presences.findIndex(searchPresence);
        this.a_Participants[Li_ParticipantIndex].a_Presences[Li_PresenceIndex].i_SSN_PresenceTypeID = Li_PresenceTypeID;
      },
      getPresenceTypeID(As_PresenceTypeText) {
        let Li_PresenceTypeIndex;
        
        Li_PresenceTypeIndex = this.a_PresenceType.findIndex(presenceType => presenceType.s_Name == As_PresenceTypeText);
        
        return this.a_PresenceType[Li_PresenceTypeIndex];
      },
      getColorIcon(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Ls_ColorIcon;
        let Li_PresenceIcon = this.getPresenceIcon(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        
        switch(Li_PresenceIcon){
          case 'mdi-check-circle':
            Ls_ColorIcon = 'green darken-2';
            break;
          case 'mdi-close-circle':
            Ls_ColorIcon = 'red darken-2';
            break;   
          default:
            Ls_ColorIcon = 'red darken-2';
            break;
        }
        
        return Ls_ColorIcon;
      },
      fillExcuses() {
        this.a_PresenceType.forEach(
          presenceType => {
            this.a_Excuses.push(presenceType.s_Name);
          }
        );
      }
    },
    beforeMount() {
      this.getClasses();
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
